import './bootstrap';
import '../css/app.css';
import.meta.glob([
    '../images/**'
]);

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/inertia-vue3';
import { InertiaProgress } from '@inertiajs/progress';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';
import * as Sentry from "@sentry/vue";

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Laravel';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, app, props, plugin }) {
        const newApp = createApp({ render: () => h(app, props) });

        /*
        Sentry.init({
                app,
                dsn: 'https://6e737632020180826b51556b32805b84@o4506223827550208.ingest.us.sentry.io/4506949340823552',
            //environment: import.meta.env.APP_ENV,
                tracesSampleRate: 1.0,
                integrations: [
                new Sentry.BrowserTracing({
                        routingInstrumentation: inertiaRoutingInstrumentation,
                }),
                Sentry.replayIntegration({
                    maskAllText: false,
                    blockAllMedia: false,
                }),
                ],
        });
        */

        newApp.use(plugin)
            .use(ZiggyVue, Ziggy)
            .mount(el);

        return newApp;
    },
});

// Uses https://inertiajs.com/events
function inertiaRoutingInstrumentation(
    customStartTransaction,
    startTransactionOnPageLoad = true,
    startTransactionOnLocationChange = true,
) {
    let activeTransaction;
    let name;
    if (startTransactionOnPageLoad) {
        name = route().current();
        activeTransaction = customStartTransaction({
        name,
        op: 'pageload',
        metadata: {
            source: 'route',
        },
        });
    }

    if (startTransactionOnLocationChange) {
        router.on('before', (_to, _from) => {
        if (activeTransaction) {
            activeTransaction.finish();
        }

        const newName = route().current();

        if (newName !== name) {
            activeTransaction = customStartTransaction({
            name: newName,
            op: 'navigation',
            metadata: {
                source: 'route',
            },
            });
        }
        });

        router.on('finish', () => {
        activeTransaction.setName(route().current(), 'route');
        });
    }
}


InertiaProgress.init({ color: '#818CF8' });
